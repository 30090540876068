.hover-underline-animation {
    @apply tw-inline-block tw-relative tw-text-current tw-transition tw-transition-colors tw-duration-200 tw-ease-in-out;

    &:hover {
        color: theme('colors.brand');
    }
}

.hover-underline-animation::after {
    @apply tw-absolute tw-block tw-w-full tw-bg-current tw-left-0;
    content: '';
    height: 1px;
    bottom: 1px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-alt {
    @apply tw-inline-block tw-relative tw-text-current;
}

.hover-underline-animation-alt::after {
    @apply tw-absolute tw-block tw-w-full tw-bg-current tw-left-0;
    content: '';
    height: 1px;
    bottom: 1px;
    transform: scaleX(1);
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-alt:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
}