.HeaderVideo {
    max-height: 85vh;

    @media screen and (min-width: theme('screens.md')) {
        height: calc(100vh - theme('header.md') - 100px);
        max-height: 875px;
    }

    video {
        @apply tw-aspect-video tw-w-full tw-h-full tw-object-cover;
    }

    .pimcore_editable_video,
    .pimcore_editable_video_progress {
        &, img {
            @apply tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-z-10 tw-aspect-video tw-block;
        }
    }

    &::after {
        @apply tw-aspect-portrait tw-block sm:tw-aspect-square md:tw-aspect-video;
        content: '';
    }
}