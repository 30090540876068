.Navigation {
    height: calc(theme('header.sm') + var(--banner-height-sm, 0px));

    @media screen and (min-width: theme('screens.md')) {
        height: calc(theme('header.md') + var(--banner-height-md, 0px));
    }

    .container {
        @apply tw-container;
        width: 100%;
        max-width: 1920px;
    }

    .keywords {
        font-size: 14px;

        @media screen and (min-width: theme('screens.md')) {
            font-size: 17px;
        }

        .keyword {
            @apply tw-flex tw-items-baseline tw-flex-nowrap tw-whitespace-nowrap;
            opacity: 0;
            pointer-events: none;
            line-height: 1.7rem;

            &::before {
                content: '¬';
                transform: rotate(130deg);
                font-size: 1.15rem;
                font-weight: bold;
                margin-right: 4px;

                @media screen and (min-width: theme('screens.lg')) {
                    font-size: 1.5rem;
                }
            }

            &:first-child {
                opacity: 1;
            }

            @media screen and (min-width: theme('screens.lg')) {
                opacity: 1;
            }
        }
    }

    .toggle {
        @apply tw-font-medium tw-pl-3;

        .circle {
            @apply tw-transition-all tw-duration-300;
        }

        &:hover {
            .circle {
                @apply tw-bg-white tw-text-brand;
            }
        }
    }

    &.sticky {
        @apply tw-border-brand;
    }
}