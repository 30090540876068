@font-face {
    font-family: 'GT-Eesti';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/GT-Eesti-Display-Light.woff2') format('woff2'), url('../fonts/GT-Eesti-Display-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'GT-Eesti';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/GT-Eesti-Display-Light-Italic.woff2') format('woff2'), url('../fonts/GT-Eesti-Display-Light-Italic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'GT-Eesti';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GT-Eesti-Display-Medium.woff2') format('woff2'), url('../fonts/GT-Eesti-Display-Medium.woff') format('woff');
    font-display: swap;
}


