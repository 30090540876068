.tw-button {
    @apply tw-inline-flex tw-transition-colors tw-duration-500 tw-ease-in-out tw-relative tw-rounded-full tw-overflow-hidden;

    > span {
        @apply tw-relative tw-z-10;
    }

    &-xs {
        @apply tw-font-light;
        padding: 2px;
    }

    &-sm {
        @apply tw-font-light tw-px-2 tw-py-1;
    }

    &-lg {
        @apply tw-text-lg tw-font-light tw-px-3 tw-py-1;
    }

    &-xl {
        @apply tw-font-light tw-px-4 tw-py-1;
    }

    &-2xl {
        @apply tw-text-lg tw-font-light tw-px-3 tw-py-1 md:tw-px-5 md:tw-py-2 md:tw-text-xl lg:tw-text-2xl;
    }

    &-nl {
        display: flex !important;
    }

    &-brand {
        @apply tw-bg-brand tw-text-white tw-border-2 tw-border-brand hover:tw-text-brand;

        &::after {
            @apply tw-bg-white;
        }
    }

    &-white {
        @apply tw-text-red-700 tw-bg-white tw-border-2 tw-border-white;

        &::after {
            @apply tw-bg-red-700;
        }

        &:hover {
            @apply tw-text-white;
        }
    }

    &-link {
        @apply tw-font-medium tw-text-black tw-p-0 tw-rounded-none;
    }

    &-outline {
        @apply tw-border-2 tw-border-brand tw-text-brand hover:tw-text-white;

        &.black {
            @apply tw-text-black hover:tw-text-white;
        }

        &::after {
            @apply tw-block tw-bg-brand;
        }
    }

    &-brand,
    &-white,
    &-outline {
        &::after {
            @apply tw-block tw-absolute tw-left-0 tw-right-0 tw-rounded-full tw-duration-500 tw-ease-in-out;
            content: '';
            z-index: 0;
            left: -1px;
            transform: scale(1.5) translate(-100%, -50%);
            transition-property: transform;
            transform-origin: left center;
            top: 80%;
            aspect-ratio: 1 / 1;
        }

        &:hover {
            &::after {
                transform: scale(1.5) translate(-25%, -50%);
            }
        }
    }

    &[disabled] {
        @apply tw-opacity-50 tw-cursor-not-allowed;
    }
}