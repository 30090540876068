.TourTeaser {
    min-width: 65vw;

    @media screen and (min-width: theme('screens.sm')) {
        min-width: 45vw;
    }

    @media screen and (min-width: theme('screens.md')) {
        min-width: auto;
    }
}