form {
    &[name="payment"] {
        // add asterisk
        label.required::after {
            content: "*";
            margin-left: 0.25rem;
        }
    }

    .no-asterisk {
        label.required {
            &::after {
                content: none;
            }
        }
    }
}