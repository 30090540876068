.tw-list {
    &-checkmark {
        padding: 0 !important;

        li {
            @apply tw-relative tw-flex tw-items-baseline;

            &::before {
                content: '¬';
                font-size: 1.2em;
                left: 0.0em;
                transform: rotate(130deg);
                font-weight: bold;
                transform-origin: center;
                margin-right: 4px;

                @media screen and (min-width: theme('screens.lg')) {
                    font-size: 1.3em;
                }
            }
        }
    }

    &-dot {
        li {
            @apply tw-pl-3 tw-relative;

            &::before {
                @apply tw-absolute tw-left-0 tw-top-3 -tw-translate-y-1/2 tw-leading-none;
                content: '•';
            }
        }
    }

    &-disc {
        @apply tw-pb-3 tw-relative;

        li {
            @apply tw-pl-1 tw-relative;

            p {
                @apply tw-m-0;
            }
        }
    }
}