.Header {
    img {
        @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-object-cover tw-z-0;
    }

    .inner {
        @media screen and (min-width: theme('screens.lg')) {
            padding-left: 25%;
        }
    }
}