@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'import-fonts';
@import 'effects';
@import 'base';
@import 'form';
@import 'buttons';
@import 'components';
@import 'container';
@import 'list';


@layer base {
    .WYSIWYG, .CountryContent, .Table {
        menu, ul, ol {
            list-style: revert;
            margin: revert;
            padding: revert;
        }
    }
}
