.AcquisitionBanner {
    .item {
        @apply tw-hidden;

        &:first-child {
            @apply tw-flex;
        }

        @media screen and (min-width: theme('screens.md')) {
            @apply tw-flex;
            min-height: 11rem;
            grid-row: span 4/span 4;
            grid-column: span 4/span 4;

            &:nth-child(2),
            &:nth-child(5) {
                grid-row: span 8/span 8;
                grid-column: span 4/span 4;
            }

            &:nth-child(6) {
                grid-row: span 4/span 4;
                grid-column: span 8/span 8;
            }
        }

        //img {
        //    @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-object-cover tw-z-0 tw-transition-transform tw-duration-500;
        //    transform: scale(1.1);
        //}
    }

    .feature {
        @media screen and (min-width: theme('screens.lg')) {
            line-height: 1.9rem;
        }

        &::before {
            font-size: 1.15rem;
            margin-right: 8px;

            @media screen and (min-width: theme('screens.lg')) {
                font-size: 1.5rem;
            }
        }
    }
}