html,
body {
    @apply tw-font-light;
    font-family: theme('fontFamily.sans');
    scroll-behavior: smooth;
    font-size: 17px;

    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        @apply tw-bg-gray-500;
        border-radius: 20px;
        border: transparent;
    }

    scroll-padding-top: calc(theme('header.sm')*1.25 + var(--banner-height-sm, 0px));

    @media screen and (min-width: theme('screens.md')) {
        scroll-padding-top: calc(theme('header.md')*1.25 + var(--banner-height-md, 0px));
    }
}

body {
    &.has-header {
        padding-top: calc(theme('header.sm') + var(--banner-height-sm, 0px));

        @media screen and (min-width: theme('screens.md')) {
            padding-top: calc(theme('header.md') + var(--banner-height-md, 0px));
        }
    }
}

p {
    @apply tw-my-2;

    a:not(.tw-button) {
        @apply hover-underline-animation;
    }
}

.Table,
.WYSIWYG,
.mark-links {
    a:not(.tw-button) {
        @apply hover-underline-animation-alt;
        color: theme('colors.brand') !important;
    }
}

header.Navigation ul,
.ScrollSpy {
    a:not(.tw-button) {
        @apply tw-leading-tight hover-underline-animation;
    }
}

.FooterContent {
    a {
        @apply tw-text-black;
    }
}


.tw-styled-link {
    a {
        @apply tw-text-brand;
    }
}

strong {
    @apply tw-font-medium;
}

.Legal {
    ul {
        list-style: disc;
        padding-left: 1.5rem;
    }
    ol {
        list-style: decimal;
        padding-left: 1.5rem;
    }
}

.YoutubeVideo {
    iframe {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
    }
}

[x-cloak] {
    display: none !important;
}

[class^="tw-rounded"],
[class*=" tw-rounded"],
img[loading="lazy"] {
    perspective: 0;
}

form {
    .checkbox {
        a {
            color: theme('colors.red.500');
        }
    }
}