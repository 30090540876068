.ItemsGrid {
    .tw-grid {
        @media screen and (min-width: theme('screens.md')) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (min-width: theme('screens.lg')) {
            grid-template-columns: repeat(36, minmax(0, 1fr));
        }
    }

    .item {
        @media screen and (min-width: theme('screens.lg')) {
            min-height: 22.5rem;
            grid-row: span 9/span 9;
            grid-column: span 10/span 10;
        }

        .content {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .headline {
            color: currentColor;
        }

        //img:not(.lazy-load-placeholder) {
        //    @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-object-cover tw-z-0 tw-transition-transform tw-transition-opacity tw-duration-500;
        //    transform: scale(1.1);
        //}

        a {
            &:hover {
                @apply tw-text-brand;
            }
        }

        @media screen and (min-width: theme('screens.lg')) {
            &:nth-child(1),
            &:nth-child(5) {
                grid-row: span 18/span 18;
                grid-column: span 10/span 10;
            }

            &:nth-child(6),
            &:nth-child(8) {
                grid-row: span 9/span 9;
                grid-column: span 25/span 25;
            }

            &:nth-child(2),
            &:nth-child(4) {
                grid-column: span 15/span 15;
            }
        }

        &::before {
            @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bg-white tw-bottom-0 tw-z-10 tw-transition-opacity tw-duration-500;
            content: '';
            pointer-events: none;
            opacity: 0.0;
        }

        &:hover {
            //img {
            //    transform: scale(1);
            //}

            &::before {
                opacity: 0.65;
            }
        }
    }
}